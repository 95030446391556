











import { SfLoader } from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  useContext,
  useRoute,
  useRouter,
  onMounted,
  useFetch,
} from '@nuxtjs/composition-api';
import { mergeItem, getItem } from '~/helpers/asyncLocalStorage';
import { useUser } from '~/modules/customer/composables/useUser';
import useMakeOrder from '~/modules/checkout/composables/useMakeOrder';
import useCart from '~/modules/checkout/composables/useCart';
import { removeItem } from '~/helpers/asyncLocalStorage';
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import { useUiNotification } from '~/composables/useUiNotification';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';
import cartGetters from '~/modules/checkout/getters/cartGetters';

export default defineComponent({
  name: 'PaypalOrder',
  layout: 'empty',
  components: {
    SfLoader,
  },

  // eslint-disable-next-line consistent-return
  middleware({ redirect, route }) {
    if (
      !route.query.payment_intent ||
      !route.query.payment_intent_client_secret ||
      !route.query.redirect_status
    ) {
      return redirect('/');
    }
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const context = useContext();
    const { selectedCurrency } = useMagentoConfiguration();

    const route = useRoute();
    const { payment_intent_client_secret, payment_intent, redirect_status } =
      route.value.query;
    const {
      loading: userLoading,
      registerPlusAddresses,
      error: userError,
      updateUser,
      isAuthenticated,
    } = useUser();
    const order = ref(null);
    const { cart, load: loadCart, setCart } = useCart();
    const { make, loading, error: makeOrderErrors } = useMakeOrder();
    const {
      save: savePayment,
      loading: paymentLoading,
      error: paymentErrors,
    } = usePaymentProvider();
    const { send: sendNotification, notifications } = useUiNotification();

    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    const homeRoute = app.localeRoute({
      name: 'home',
    });

    // useFetch(async() => {
    // });

    const dlPurchaseEvent = () => {
      if (cart.value == null) return;

      try {
        let dataLayerProducts = [];
        let orderNumber = order?.value?.order?.order_number || '';
        // let isAnySub = false;
        let totalAmmount = cart?.value?.prices?.grand_total?.value || 0.0;

        cart.value.items.forEach((el) => {
          // @ts-ignore
          // prettier-ignore
          let isAnySub = el.customizable_options && el.customizable_options.length > 0;

          let productObj = {
            item_id: el.product.sku,
            item_name: el.product.name,
            price: parseFloat(
              (cartGetters.getItemPrice(el).regular / el.quantity).toFixed(2),
            ),
            quantity: el.quantity,
            item_variant: isAnySub ? 'subscription' : 'onetime',
          };

          dataLayerProducts.push(productObj);
        });

        let taxes = 0.0;
        let couponCode = '';
        let shippingCost = 0.0;

        if (
          cart.value.shipping_addresses != null &&
          cart.value.shipping_addresses.length > 0
        ) {
          shippingCost =
            cart.value.shipping_addresses[0].selected_shipping_method.amount
              .value;
        }
        if (
          cart.value.prices.applied_taxes != null &&
          cart.value.prices.applied_taxes.length > 0
        ) {
          cart.value.prices.applied_taxes.forEach((el) => {
            taxes += el.amount.value;
          });
        }
        if (
          cart.value.applied_coupons != null &&
          cart.value.applied_coupons.length > 0
        ) {
          couponCode = cart.value.applied_coupons[0].code;
        }

        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: 'purchase',
          // purchase_type: (isAnySub) ? 'subscription' : "onetime",
          // prescription_code: 'xxxx',
          ecommerce: {
            tax: taxes,
            shipping: shippingCost,
            coupon: couponCode, //promotion or discount code if applicable
            currency: selectedCurrency.value, // 3 letter currency Required
            value: totalAmmount, // total value Required
            transaction_id: orderNumber, // real transaction Id recorded in the backe Required
            items: dataLayerProducts,
          },
        });
      } catch (e) {}
    };

    const stripeApiKey = process.env.VSF_STRIPE_API_KEY;
    onMounted(async () => {
      if (redirect_status !== 'succeeded') {
        const cartPage = app.localeRoute({ name: 'cart' });
        await router.push(cartPage);
        return false;
      }

      try {
        // @ts-ignore
        let stripeObj = window.Stripe(stripeApiKey);

        if (!stripeObj) throw new Error('Stripe module not loaded.');
        let paymentData = await stripeObj.retrievePaymentIntent(
          payment_intent_client_secret,
        );

        let paymentTokenId = paymentData?.paymentIntent?.payment_method || '';

        if (!paymentTokenId) throw new Error('Stripe payment method issue.');

        await loadCart();

        if (!cart?.value) {
          throw new Error('Cart data not available.');
          // await router.push(homeRoute);
        }

        let cartId = cart.value.id;

        // set payment methods
        await savePayment({
          paymentMethod: {
            code: 'stripe_payments',
            stripe_payments: {
              cc_stripejs_token: paymentTokenId,
            },
          },
        });

        if (paymentErrors.value.save !== null) {
          throw new Error(paymentErrors.value.save.message);
        }

        order.value = await make();

        if (makeOrderErrors.value.make !== null) {
          throw new Error(makeOrderErrors.value.make.message);
        }

        if (order.value == null) {
          throw new Error('Order not placed, unknow error.');
        }

        // fetch checkout data
        let checkout = await getItem('checkout');
        let actionType = checkout ? checkout['user-account-action'] : '';

        if (isAuthenticated.value) {
          let personalDetails = checkout ? checkout['personal-data'] : null;

          if (personalDetails !== null && personalDetails?.value !== null) {
            await updateUser({
              user: {
                firstname: personalDetails.value.firstname,
                lastname: personalDetails.value.lastname,
                prefix: personalDetails.value.prefix,
                phonenumber: personalDetails.value.telephone,
                marketing_note: personalDetails.value.marketing_note,
                // @ts-ignore
                gender: personalDetails.value.gender || 0,
              },
            });
          }
        }

        if (actionType == 'register') {
          let userData = checkout['user-account'];
          let addressArr = checkout['user-account-address'];

          await registerPlusAddresses({
            user: userData,
            addressCode: addressArr,
          });

          if (userError.value.register !== null) {
            throw new Error(userError.value.register.message);
          }
        }

        dlPurchaseEvent();

        setCart(null);
        // @ts-ignore
        app.$vsf.$magento.config.state.removeRefCode();
        app.$vsf.$magento.config.state.removeCartId();

        // await load();
        await removeItem('checkout');

        const thankYouRoute = app.localeRoute({
          name: 'typo3',
          params: { slug: 'checkout-thank-you' },
          query: {
            token: cartId,
          },
        });
        await router.push(thankYouRoute);
      } catch (e) {
        sendNotification({
          icon: 'error',
          id: Symbol(`paypal_order_error`),
          message: e.message,
          persist: true,
          title: 'Paypal Order Error',
          type: 'danger',
        });
      }
    });

    return {};
  },
});
